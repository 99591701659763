import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import BasePurpleButtonComponent from "../../../../components/BasePurpleButtonComponent";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { sendPhoneCode } from "../../../../actions/phoneVerification";
import { useNavigate } from "react-router-dom";
import { sendCotizacion } from "../../service/cotizar";
import { useSpinner } from "../../../../context/spinnerContext";
import SpinnerComponent from "../../../../components/SpinnerComponent";
import IconEdit from "../../images/icon-edit.svg";
import IconClose from "../../images/icon-close.svg";

export const ViewStepOneComponent = ({
  selectedOptions,
  isGnc,
  kms,
  setCurrentView,
  setPriceQuote,
  setPhoneNumber
}) => {
  let isPageWide = useMediaQuery("(min-width: 1000px)");
  const { token, name, email } = useSelector((state) => state.authReducer);
  const { phone, verified_phone } = useSelector((state) => state.userReducer);
  const { register, handleSubmit, setValue, getValues  } = useForm();
  const [codArea, setcodArea] = useState(8);
  const [isLoading, setIsLoading] = useState();
  const [editName, setEditName] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showLoading, hideLoading, showSpinner } = useSpinner();

  useEffect(() => {
    if (verified_phone === null) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [verified_phone]);

  useEffect(() => {
    setValue("name", name);
    setValue("email", email);
  }, []);

  const handleChange = (e, inputName) => {
    setValue(inputName, e.target.value);
  };

  const sendSale = (data) => {
    setIsLoading(true);
    if (verified_phone) {
      sendCotizacion(
        selectedOptions?.brand?.name,
        selectedOptions?.brand?.id,
        selectedOptions?.model?.name,
        selectedOptions?.model?.id,
        selectedOptions?.version?.name,
        selectedOptions?.version?.id,
        selectedOptions?.year?.name,
        isGnc,
        kms,
        phone,
        name,
        email,
        setIsLoading,
        setCurrentView,
        setPriceQuote,
        navigate,
        dispatch
      );
    } else {
      const phone = `549${data.cod_area}${data.telefono}`;
      setPhoneNumber(phone);
      sendPhoneCode(token, phone, dispatch);
      setTimeout(() => {
        setCurrentView("step2");
        setIsLoading(false);
      }, 2000);
    }
  };
  
  const sendAutaSale = () => {
    setIsLoading(true);
    sendCotizacion(
      selectedOptions?.brand?.name,
      selectedOptions?.brand?.id,
      selectedOptions?.model?.name,
      selectedOptions?.model?.id,
      selectedOptions?.version?.name,
      selectedOptions?.version?.id,
      selectedOptions?.year?.name,
      isGnc,
      kms,
      `549${getValues("cod_area")}${getValues("telefono")}`,
      getValues("name"),
      getValues("email"),
      setIsLoading,
      setCurrentView,
      setPriceQuote,
      navigate,
      dispatch
    );
  };

  return (
    <>
      <div
        className={
          isPageWide ? "card-contact-data" : "mobile-dropdown-container"
        }
      >
        <div className="card-contact-header">
          <p className="contact-header-title">
            {verified_phone ?
              "¡Estos son tus datos!"
              :
              "¡Completá tus datos!"
            }
          </p>
        </div>
        {showSpinner ? (
          <div style={{ marginTop: "50px" }}>
            <SpinnerComponent />
          </div>
        ) : (
          <>
            <div style={{ padding: "12px", height: "364px" }}>
              <form
              onSubmit={handleSubmit(email?.includes("@auta.com.ar") ? sendAutaSale : sendSale)}
              style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {email?.includes("@auta.com.ar") ?
                  <div>
                    <div>
                      <label className="input-labels">Nombre</label>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {editName ?
                          <>
                            <input
                              className="base-input"
                              {...register("name", {
                                required: true,
                              })}
                              placeholder="Ingresá el nombre"
                              onChange={(e) => handleChange(e, "name")}
                              style={{ height: "30px" }}
                            />
                            <img
                              src={IconClose}
                              alt="edit-icon"
                              style={{ height: "24px", cursor: "pointer", alignSelf: "center" }}
                              className=""
                              onClick={() => setEditName(false)}
                            />
                          </>
                          :
                          <>
                            <p style={{ height: "30px", alignContent: "center" }}>{getValues("name") ?? name}</p>
                            <img
                              src={IconEdit}
                              alt="edit-icon"
                              style={{ height: "24px", cursor: "pointer", alignSelf: "center" }}
                              className=""
                              onClick={() => setEditName(true)}
                            />
                          </>
                        }
                      </div>
                    </div>
                    <div>
                      <label className="input-labels">Email</label>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {editEmail ?
                          <>
                            <input
                              className="base-input"
                              {...register("email", {
                                required: true,
                              })}
                              placeholder="Ingresá el mail"
                              onChange={(e) => handleChange(e, "email")}
                              style={{ height: "30px" }}
                            />
                            <img
                              src={IconClose}
                              alt="edit-icon"
                              style={{ height: "24px", cursor: "pointer", alignSelf: "center" }}
                              onClick={() => setEditEmail(false)}
                            />
                          </>
                          :
                          <>
                            <p style={{ height: "30px", alignContent: "center" }}>{getValues("email") ?? email}</p>
                            <img
                              src={IconEdit}
                              alt="edit-icon"
                              style={{ height: "24px", cursor: "pointer", alignSelf: "center" }}
                              onClick={() => setEditEmail(true)}
                            />
                          </>
                        }
                      </div>
                    </div>
                    <div>
                      <label className="input-labels">Teléfono celular</label>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {editPhone ?
                          <>
                            <label className="phone-number" style={{ height: "30px", alignContent: "center", fontSize: "16px" }}>+549</label>
                            <input
                              type="text"
                              className="base-input"
                              placeholder="Ej: 11"
                              maxLength={3}
                              style={{ width: "65px", height: "24px", fontSize: "16px" }}
                              {...register("cod_area", {
                                required: true,
                              })}
                              onChange={(e) => handleChange(e, "cod_area")}
                            />
                            <input
                              type="text"
                              className="base-input"
                              placeholder="Sin 15"
                              {...register("telefono", {
                                required: true,
                              })}
                              style={{ height: "24px", fontSize: "16px" }}
                              onChange={(e) => handleChange(e, "telefono")}
                            />
                            <img
                              src={IconClose}
                              alt="edit-icon"
                              style={{ height: "24px", cursor: "pointer", alignSelf: "center" }}
                              onClick={() => setEditPhone(false)}
                            />
                          </>
                          :
                          <>
                            <p style={{ height: "30px", alignContent: "center" }}>{getValues("telefono") ? `+549${getValues("cod_area"), getValues("telefono")}` : phone}</p>
                            <img
                              src={IconEdit}
                              alt="edit-icon"
                              style={{ height: "24px", cursor: "pointer", alignSelf: "center" }}
                              onClick={() => setEditPhone(true)}
                            />
                          </>
                        }
                      </div>
                    </div>
                  </div>
                  :
                  <div>
                    <div style={{ marginBottom: "12px" }}>
                      <label className="input-labels">Nombre</label>
                      <p>{name}</p>
                    </div>
                    <div style={{ marginBottom: "12px" }}>
                      <label className="input-labels">Email</label>
                      <p>{email}</p>
                    </div>
                    <div style={{ marginBottom: "12px" }}>
                      <label className="input-labels">Teléfono celular</label>
                      {verified_phone ?
                        <p>+{phone}</p>
                        :
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <p className="phone-number">+549</p>
                          <input
                            type="text"
                            className="base-input"
                            placeholder="Ej: 11"
                            maxLength={3}
                            style={{ width: "65px", marginRight: "10px" }}
                            {...register("cod_area", {
                              required: true,
                            })}
                            onChange={(e) => {
                              setcodArea(e.target.value.length);
                            }}
                          />
                          <input
                            type="text"
                            className="base-input ms-1"
                            placeholder="Sin 15"
                            {...register("telefono", {
                              required: true,
                              maxLength: codArea ? Math.abs(codArea - 10) : 0,
                              minLength: codArea ? Math.abs(codArea - 10) : 0,
                            })}
                            maxLength={
                              codArea ? Math.abs(parseInt(codArea.length) - 10) : 0
                            }
                            minLength={
                              codArea ? Math.abs(parseInt(codArea.length) - 10) : 0
                            }
                          />
                        </div>
                      }
                    </div>
                  </div>
                }
                <div className="d-flex flex-column justify-content-between align-items-center">
                  {!verified_phone &&
                    <p className="security-code-text">
                      Recibirás un código de seguridad.
                    </p>
                  }
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      style={{
                        margin: "10px",
                        color: " #7340d8",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <BasePurpleButtonComponent
                    buttonText={isLoading ? "Enviando..." : "Continuar"}
                    buttonDisabled={isLoading ? true : false}
                    type="submit"
                  />
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </>
  );
};
